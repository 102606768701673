<template>
    <v-dialog
      width="500"
      persistent="true"
      v-model="openedModal"
    >
        <v-card>
            <v-card-text>
                <br>
                <p>
                    Чтобы устаноновить приложение, просто нажмите <img src="@/assets/images/ios-options.png">
                    затем нажмите "На экран «Домой»"
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="openedModal = false"
                >
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {
        VDialog,

        VCard,
        VCardText,
        VCardActions,

        VSpacer,

        VBtn,
    } from 'vuetify/lib'

    export default {
        name: 'AppleModal',
        components: {
            VDialog,
            VCard,
            VCardText,
            VCardActions,
            VSpacer,
            VBtn,
        },
        props: {
            showModal: Boolean
        },
        computed: {
            openedModal: {
                get() {
                    return this.showModal
                },
                set(value) {
                    this.$emit('update:showModal', value)
                }
            }
        }
    }
</script>

<style scoped>
img {
  height: 30px;
}
</style>
